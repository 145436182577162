.fc {
    --fc-border-color: #eff2f5;
    --fc-page-bg-color: #ffffff;
    --fc-small-font-size: 0.95rem;
    --fc-highlight-color: f5f8fa;
    --fc-bg-event-opacity: 0.3;
    --fc-neutral-bg-color: f5f8fa;
    --fc-today-bg-color: #fff8dd;
    --fc-now-indicator-color: #f1416c;
    --fc-list-event-hover-bg-color: f5f8fa;
    --fc-button-text-color: #7E8299;
    --fc-button-bg-color: #f5f8fa;
    --fc-button-border-color: #f5f8fa;
    --fc-button-hover-bg-color: #f5f8fa;
    --fc-button-hover-border-color: #f5f8fa;
    --fc-button-active-bg-color: #eff2f5;
    --fc-button-active-border-color: #eff2f5;
}

.fc .fc-button {
    padding: 0.75rem 1.25rem;
    box-shadow: none !important;
    border: 0 !important;
    border-radius: 0.475rem;
    vertical-align: middle;
    font-weight: 500;
    text-transform: capitalize;
}

.fc .fc-button-primary {
    color: #fff;
    color: var(--fc-button-text-color, #fff);
    background-color: #2c3e50;
    background-color: var(--fc-button-bg-color, #2c3e50);
    border-color: #2c3e50;
    border-color: var(--fc-button-border-color, #2c3e50);
}

.fc .fc-col-header-cell {
    padding: 0.75rem 0.5rem;
}

.fc .fc-col-header-cell .fc-col-header-cell-cushion {
    font-size: 1.1rem;
    font-weight: 500;
    color: #3F4254;
}

.fc .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 2px 4px;
}

.fc .fc-toolbar-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #3F4254;
}

.fc .fc-toolbar-title {
    font-size: 1.75em;
    margin: 0;
}

.fc .fc-daygrid-block-event .fc-event-time,
.fc .fc-daygrid-block-event .fc-event-title,
.fc .fc-daygrid-dot-event .fc-event-time,
.fc .fc-daygrid-dot-event .fc-event-title {
    padding: 0.25rem 0.25rem;
}

.fc-icon.fa {
    font: normal normal normal 14px/1 FontAwesome !important;
}

.fc-day-sat[role="gridcell"],
.fc-day-sun[role="gridcell"] {
    background-color: #f0ffff !important;
}

.fc-day-holyday[role="gridcell"] {
    background-color: #0d9999 !important;
}