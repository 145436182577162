.carousel-control-prev {
    margin-left: -70px;
}

.carousel-control-prev i {
    font-size: xx-large;
}

.carousel-control-next {
    margin-right: -70px;
}

.carousel-control-next i {
    font-size: xx-large;
}