.rdt_TableHeadRow {
    text-transform: uppercase;
}

body {
    --fc-event-border-color: var(--kt-primary);
    --fc-event-bg-color: var(--kt-primary);
    --fc-event-text-color: var(--kt-primary-inverse);
}

.rdt_TableRow, .rdt_TableHeadRow {
    border-bottom: 1px dashed #eff2f5 !important;
    border-bottom-color: #eff2f5 !important;
    &:last-child {
        border-bottom: none !important;
    }
}

.rdt_Pagination {
    border-top-style: none !important;
}

.rdt_TableRow, .rdt_TableHeadRow {
    div {
        &:first-child {
            input {
                text-align: center;
                display: table-cell;
                vertical-align: middle;
                appearance: none;
                border-radius: 10%;
                box-shadow: none;
                font-size: 1em;
                border: 0;
                border-radius: 0.45rem;
                flex-shrink: 0;
                float: none;
                margin: 0;
                appearance: none;
                background-position: 50%;
                background-repeat: no-repeat;
            }

            input[type="checkbox"] {
                background-color: #eff2f5;
                height: 1.55rem !important;
                width: 1.55rem !important;
            }

            input[type="checkbox"]:checked {
                background-color: #009ef7;
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='11' fill='none'%3E%3Cpath d='M11.043 1.029a.792.792 0 1 1 1.206 1.025l-6.73 7.917a.792.792 0 0 1-1.128.079L.828 6.883A.792.792 0 1 1 1.88 5.7l2.958 2.629 6.205-7.3Z' fill='%23fff'/%3E%3C/svg%3E")
            }

            .input:not(.form-switch) .input {
                background-size: 60% 60%;
            }
        }
    }
}
