.MuiPopover-paper {
  box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%) !important;
}

.MuiSelect-select,
.MuiAutocomplete-root {
  color: #5e6278 !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  background-color: #f5f8fa !important;
  border: 1px solid #f5f8fa;
  border-radius: 0.475rem !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}

.MuiAutocomplete-inputRoot {
  padding: 0 !important;
}

.MuiAutocomplete-input {
  padding: 0.9rem 1.5rem !important;
  color: #5e6278 !important;
  font-size: 1.15rem !important;
  font-weight: 500 !important;
  font-family: 'Poppins', sans-serif !important;
}

.MuiAutocomplete-input::placeholder {
  font-weight: 500 !important;
}

.MuiAutocomplete-input:disabled {
  background-color: #eff2f5;
  border-radius: 0.475rem !important;
}

.autocomplete-md {
  font-size: 1.1rem !important;
  padding: 0;
}

.autocomplete-md .MuiAutocomplete-input {
  padding: 0.75rem 1rem !important;
}

.autocomplete-md input::placeholder {
  font-size: 1.1rem !important;
}

.MuiPaper-root .MuiList-root {
  border: 0 !important;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
  border-radius: 0.475rem !important;
  border-bottom: none !important;
  padding: 1rem 0 !important;
}

.MuiMenuItem-root,
.MuiAutocomplete-option,
.MuiAutocomplete-noOptions {
  font-family: 'Poppins', sans-serif !important;
  color: #785e72 !important;
  padding: 0.75rem 1.25rem !important;
  font-weight: 500 !important;
}

.MuiInputBase-input {
  font-family: 'Poppins', sans-serif !important;
  font-size: 1.15rem !important;
  font-weight: 500 !important;
}

.Mui-disabled {
  color: #5e6278 !important;
}

.MuiInputBase-input::placeholder {
  color: #a1a5c6 !important;
  opacity: 1 !important;
}

.MuiAutocomplete-popupIndicatorOpen:hover {
  background-color: inherit !important;
}

.Mui-selected,
.MuiAutocomplete-option[aria-selected='true'] {
  background-color: #f4f6fa !important;
  color: #009ef7 !important;
}

.MuiMenuItem-root:hover,
.MuiAutocomplete-option:hover {
  color: #009ef7 !important;
}

.MuiAutocomplete-paper {
  background-color: #fff !important;
  border: 0 !important;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
  border-radius: 0.475rem !important;
  border-bottom: none !important;
  padding: 1rem 0 !important;
}

.MuiChip-root {
  font-family: 'Poppins', sans-serif !important;
}

.MuiPaper-root {
  border: 0 !important;
  border-radius: 0.475rem !important;
  border-bottom: none !important;
}

.MuiAccordion-root:before {
  content: none !important;
}

.MuiAutocomplete-popupIndicator.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}