.text-container {
  position: relative;
  padding-left: 5px;
}

.editor-input {
  height: 100%;
  position: absolute;
  width: 100%;
  padding-right: 10px;
}

.editor-input > textarea {
  height: 100% !important;
  border: none;
  padding-top: 30px !important;
}
