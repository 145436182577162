// TODO: Make these independent from lib example showcase

@font-face {
  font-family: 'Poppins';
  src: url('./../fonts/poppins-medium-webfont.woff2');
  font-weight: medium;
}

@font-face {
  font-family: 'Poppins';
  src: url('./../fonts/poppins-regular-webfont.woff2');
  font-weight: normal;
}
