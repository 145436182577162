// TODO: Make these custom styles a styled component to prevent global styling

.MuiInputBase-root.MuiFilledInput-root {
  border-radius: 7px;
  height: 45.88px !important;
  background-color: #f5f8fa !important;
}

.MuiInputBase-input.MuiFilledInput-input {
  // width: 100%;
  padding: 0.825rem 1.5rem !important;
  font-family: Poppins, Helvetica, sans-serif;
}

.MuiInputBase-input.MuiFilledInput-input:focus {
  background-color: #faf5f9;
  border-radius: 0.475rem;
}

.MuiFilledInput-underline {
  &::after,
  &::before {
    display: none;
  }
}
